import { Button, Breadcrumb, Layout, Menu } from "antd";
import { useEffect } from "react";
import "antd/dist/antd.min.css";
import "./App.css";
import { useAppSelector, useAppDispatch } from "./store/hooks";
import GameClient, { GetUser } from "./GameClient";
import { updateGame } from "./store/gameSlice";
import { setLoggedInUser } from "./store/userSlice";
import { Outlet, Link } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const App: React.FC = () => {
  const user = useAppSelector((state) => state.user.loggedInUser);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const user = GetUser();
    if (user) {
      dispatch(setLoggedInUser(user));
    }
  }, []);

  return (
    <Layout className="layout">
      <Header>
        <Link className="nav-link" to="/">
          Home
        </Link>
        {user && (
          <Link className="nav-link" to="/game">
            Select Game
          </Link>
        )}
        {user && (
          <Link className="nav-link" to="/game/new">
            New Game
          </Link>
        )}
        {user ? (
          <Link className="nav-link-login" to="/login">
            Player: {user.username}
          </Link>
        ) : (
          <Link className="nav-link-login" to="/login">
            Login
          </Link>
        )}
      </Header>
      <Outlet></Outlet>
      <Footer style={{ textAlign: "center" }}>
        ©2022 Website Created by Kevin Nguyen
      </Footer>
    </Layout>
  );
};

export default App;
