import React from "react";
import { Button } from "antd";
import "./PlayerActions.css";
import GameClient from "../../GameClient";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { GamePhase, updateGame } from "../../store/gameSlice";
import { resetChosenCards } from "../../store/chosenCardsSlice";

const PlayerActions: React.FC = () => {
  const dispatch = useAppDispatch();

  const chosenCards = useAppSelector((state) => state.chosenCards.cards);
  const game = useAppSelector((state) => state.game);
  const user = useAppSelector((state) => state.user.loggedInUser);
  const gameID = useAppSelector((state) => state.game.id);
  const gameInfo = [];
  const moneyInfo: React.ReactElement[] = [];
  game.players.forEach((player) => {
    moneyInfo.push(
      <div>
        <b style={{ color: "white" }}>
          {player.name}: ${player.money}
        </b>
      </div>
    );
  });

  switch (game.currentPhase) {
    case GamePhase.Initial:
      gameInfo.push(
        <h1 className="player-actions-game-status">
          Waiting for game to start
        </h1>
      );
      break;
    case GamePhase.Finished:
      gameInfo.push(
        <h1 className="player-actions-game-status">
          {game.players[game.currentPlayerIndex].name} won!
        </h1>
      );
      break;
    default:
      gameInfo.push(
        <h1 className="player-actions-game-status">
          {game.players[game.currentPlayerIndex].name} needs to{" "}
          {game.currentPhase}
        </h1>
      );
      break;
  }

  const startGame = async () => {
    try {
      const game = await GameClient.StartGame(gameID);
      dispatch(updateGame(game));
    } catch (e) {
      alert(e);
    }
  };

  const joinGame = async () => {
    try {
      const game = await GameClient.JoinGame(gameID);
      dispatch(updateGame(game));
    } catch (e) {
      alert(e);
    }
  };

  const refreshGame = async () => {
    try {
      const game = await GameClient.GetGame(gameID);
      dispatch(updateGame(game));
    } catch (e) {
      alert(e);
    }
  };

  const roll = async (numDice: number) => {
    try {
      const game = await GameClient.Roll(gameID, numDice);
      dispatch(updateGame(game));
    } catch (e) {
      alert(e);
    }
  };

  const skipBuy = async () => {
    try {
      const game = await GameClient.SkipBuy(gameID);
      dispatch(updateGame(game));
    } catch (e) {
      alert(e);
    }
  };

  const confirmChoice = async () => {
    try {
      const playerCards = chosenCards.filter((card) => {
        return card.playerId === user?.id;
      });
      if (playerCards.length > 1) {
        alert("Too many cards selected");
        return;
      }
      const playerCard = playerCards.length > 0 ? playerCards[0].cardName : "";

      const opponentCards = chosenCards.filter((card) => {
        return card.playerId !== user?.id;
      });
      if (opponentCards.length > 1) {
        alert("Too many cards selected");
        return;
      }
      const opponentId =
        opponentCards.length > 0 ? opponentCards[0].playerId : undefined;
      const opponentCard =
        opponentCards.length > 0 ? opponentCards[0].cardName : "";

      const game = await GameClient.Choose(
        gameID,
        playerCard,
        opponentId,
        opponentCard
      );

      dispatch(updateGame(game));
      dispatch(resetChosenCards());
    } catch (e) {
      alert(e);
    }
  };

  let yourTurn = false;
  if (game.players && game.players[game.currentPlayerIndex]) {
    yourTurn = game.players[game.currentPlayerIndex].id === user?.id;
  }

  return (
    <div className="player-actions">
      <div>
        {game.currentPhase === "initial" && (
          <Button
            className="action-buttons"
            type="primary"
            shape="round"
            onClick={() => joinGame()}
          >
            Join Game
          </Button>
        )}
        {game.currentPhase === "initial" && (
          <Button
            className="action-buttons"
            type="primary"
            shape="round"
            onClick={() => startGame()}
          >
            Start Game
          </Button>
        )}
        {yourTurn && game.currentPhase === "roll" && (
          <Button
            className="action-buttons"
            type="primary"
            shape="round"
            onClick={() => roll(1)}
          >
            Roll 1 Dice
          </Button>
        )}
        {yourTurn && game.currentPhase === "roll" && (
          <Button
            className="action-buttons"
            type="primary"
            shape="round"
            onClick={() => roll(2)}
          >
            Roll 2 Dice
          </Button>
        )}
        {yourTurn &&
          (game.currentPhase === "initial_buy" ||
            game.currentPhase === "buy") && (
            <Button
              className="action-buttons"
              type="primary"
              shape="round"
              onClick={() => skipBuy()}
            >
              Skip Buy
            </Button>
          )}
        {yourTurn && game.currentPhase === "choose" && (
          <Button
            className="action-buttons"
            type="primary"
            shape="round"
            onClick={() => confirmChoice()}
          >
            Choice Confirmed
          </Button>
        )}
        <Button
          className="action-buttons"
          type="primary"
          shape="round"
          onClick={() => refreshGame()}
        >
          Refresh
        </Button>
        {gameInfo}
      </div>
      {moneyInfo}
    </div>
  );
};

export default PlayerActions;
