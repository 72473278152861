import { GameState, GamePhase } from "./store/gameSlice";
import { useAppDispatch } from "./store/hooks";
import { User, setLoggedInUser } from "./store/userSlice";
import { decodeJwt } from "jose";

type serverCardPile = {
  establishment: {
    name: string;
  };
  count: number;
};

type serverPlayer = {
  id: number;
  username: string;
  money: number;
  establishments: serverCardPile[];
};

type serverGameState = {
  id: number;
  market: {
    oneSix: { row: serverCardPile[] };
    sevenTwelve: { row: serverCardPile[] };
    landmarks: { row: serverCardPile[] };
  };
  players: serverPlayer[];
  currentPhase: string;
  currentPlayerIndex: number;
  turnNumber: number;
  actionHistory: string[];
};

const GetJwtToken = (): string => {
  const jwt = sessionStorage.getItem("jwt");
  if (jwt) {
    return jwt;
  }

  throw new Error("user not logged in");
};

export const GetUser = (): User | null => {
  const userId = sessionStorage.getItem("userId");
  const username = sessionStorage.getItem("username");

  if (!userId || !username) {
    return null;
  }

  return {
    id: parseInt(userId),
    username: username,
  };
};

class GameClient {
  url: string;
  constructor() {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("missing REACT_APP_API_URL");
    }
    this.url = process.env.REACT_APP_API_URL;
  }

  Login = async (username: string, password: string): Promise<User> => {
    const response = await fetch(this.url + "/login", {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const token = await response.text();

    const claims = decodeJwt(token);
    const userId =
      typeof claims.user_id === "number" ? claims.user_id : undefined;

    if (!userId) {
      throw new Error("userID not valid from jwt");
    }
    sessionStorage.setItem("jwt", token);
    sessionStorage.setItem("userId", userId.toString());
    sessionStorage.setItem("username", username);
    return { id: userId, username: username };
  };

  Logout = () => {
    sessionStorage.clear();
  };

  NewGame = async (businessCenterBehavior: string): Promise<GameState> => {
    const response = await fetch(this.url + "/game/new", {
      method: "POST",
      headers: {
        Authorization: GetJwtToken(),
      },
      body: JSON.stringify({ businessCenterBehavior: businessCenterBehavior }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  JoinGame = async (gameID: number): Promise<GameState> => {
    const response = await fetch(this.url + `/game/${gameID}/join`, {
      method: "POST",
      headers: {
        Authorization: GetJwtToken(),
      },
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  StartGame = async (gameID: number): Promise<GameState> => {
    const response = await fetch(this.url + `/game/${gameID}/start`, {
      method: "POST",
      headers: {
        Authorization: GetJwtToken(),
      },
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  Roll = async (gameID: number, numDice: number): Promise<GameState> => {
    const response = await fetch(this.url + `/game/${gameID}/roll`, {
      method: "POST",
      headers: {
        Authorization: GetJwtToken(),
      },
      body: JSON.stringify({
        numDice: numDice,
      }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  GetGame = async (gameID: number): Promise<GameState> => {
    const response = await fetch(this.url + "/game/" + gameID, {
      headers: {
        Authorization: GetJwtToken(),
      },
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  BuyCard = async (
    gameID: number,
    establishmentName: string
  ): Promise<GameState> => {
    const response = await fetch(this.url + "/game/" + gameID + "/buy", {
      method: "POST",
      headers: {
        Authorization: GetJwtToken(),
      },
      body: JSON.stringify({
        establishmentName: establishmentName,
      }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  SkipBuy = async (gameID: number): Promise<GameState> => {
    const response = await fetch(this.url + "/game/" + gameID + "/skip", {
      method: "POST",
      headers: {
        Authorization: GetJwtToken(),
      },
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  Choose = async (
    gameID: number,
    playerCard: string,
    opponentId?: number,
    opponentCard?: string
  ): Promise<GameState> => {
    const response = await fetch(this.url + "/game/" + gameID + "/choose", {
      method: "POST",
      headers: {
        Authorization: GetJwtToken(),
      },
      body: JSON.stringify({
        playerCard: playerCard,
        opponentId: opponentId,
        opponentCard: opponentCard,
      }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return this.translateServerGameState(await response.json());
  };

  translateServerGameState = (state: serverGameState): GameState => {
    return {
      id: state.id,
      market: {
        oneSix: state.market.oneSix.row.map((pile) => {
          return { name: pile.establishment.name, count: pile.count };
        }),
        sevenTwelve: state.market.sevenTwelve.row.map((pile) => {
          return { name: pile.establishment.name, count: pile.count };
        }),
        landmarks: state.market.landmarks.row.map((pile) => {
          return { name: pile.establishment.name, count: pile.count };
        }),
      },
      players: state.players.map((p) => {
        return {
          id: p.id,
          name: p.username,
          establishments: p.establishments.map((e) => {
            return { name: e.establishment.name, count: e.count };
          }),
          money: p.money,
        };
      }),
      currentPlayerIndex: state.currentPlayerIndex,
      currentPhase: state.currentPhase as GamePhase,
      actionHistory: state.actionHistory,
    };
  };
}

const gameClient = new GameClient();
export default gameClient;
