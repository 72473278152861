import React, { useState } from "react";
import { Divider, Space, Card, Row } from "antd";
import { CardPile } from "../../store/gameSlice";
import "./PlayerArea.css";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { addChosenCard, removeChosenCard } from "../../store/chosenCardsSlice";

type CardPileProps = {
  playerId: number;
  cardName: string;
  count: number;
};

const CardPileComponent: React.FC<CardPileProps> = ({
  playerId,
  cardName,
  count,
}) => {
  const game = useAppSelector((state) => state.game);
  const chosenCards = useAppSelector((state) => state.chosenCards.cards);
  const cardSelected = chosenCards.some((card) => {
    return playerId === card.playerId && cardName === card.cardName;
  });

  const dispatch = useAppDispatch();

  const selectCard = () => {
    if (game.currentPhase !== "choose") {
      return;
    }
    if (cardSelected) {
      dispatch(removeChosenCard({ playerId: playerId, cardName: cardName }));
      return;
    }
    dispatch(addChosenCard({ playerId: playerId, cardName: cardName }));
  };

  const style: React.CSSProperties = cardSelected
    ? { width: 170, border: "10px solid red" }
    : { width: 170 };
  return (
    <Card
      hoverable
      style={style}
      cover={<img alt="example" src={"/cards/" + cardName + ".jpg"} />}
      bodyStyle={{ padding: "0" }}
      onClick={() => selectCard()}
    >
      <div className="card-count">{count}</div>
    </Card>
  );
};

type MarketRowProps = {
  playerId: number;
  cardPiles: CardPile[];
};
const MarketRow: React.FC<MarketRowProps> = ({ playerId, cardPiles }) => {
  let piles: React.ReactElement[] = [];
  cardPiles.forEach((pile) => {
    piles.push(
      <CardPileComponent
        playerId={playerId}
        cardName={pile.name}
        count={pile.count}
      ></CardPileComponent>
    );
  });

  return (
    <Space direction="horizontal" size="small" style={{ display: "flex" }}>
      {piles}
    </Space>
  );
};

const PlayerArea: React.FC = () => {
  const players = useAppSelector((state) => state.game.players);
  const userId = useAppSelector((state) => state.user.loggedInUser?.id);

  const playerSections = players.map((player) => {
    return (
      <div>
        <div>
          Username: {player.name} <b>{player.id === userId ? "(You)" : ""}</b>
        </div>
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          <MarketRow
            playerId={player.id}
            cardPiles={player.establishments}
          ></MarketRow>
        </Space>
        <Divider
          style={{ margin: 4, borderWidth: 5, borderColor: "#0d4fba" }}
        ></Divider>
      </div>
    );
  });

  return (
    <div className="player-area">
      <Divider
        style={{ margin: 4, borderWidth: 5, borderColor: "#0d4fba" }}
      ></Divider>
      {playerSections}
    </div>
  );
};

export default PlayerArea;
