import { Input, Button, Form, Layout } from "antd";
import React, { useEffect } from "react";
import "antd/dist/antd.min.css";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import GameClient from "../../GameClient";
import Market from "./Market";
import PlayerArea from "./PlayerArea";
import PlayerActions from "./PlayerActions";
import { updateGame } from "../../store/gameSlice";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useInterval } from "usehooks-ts";

const { Content } = Layout;

export const SelectGame: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const { gameID } = values;
    const game = await GameClient.GetGame(gameID);
    dispatch(updateGame(game));
    navigate(`/game/${game.id}`);
  };

  return (
    <Content style={{ padding: "50px 50px" }}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 2 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="GameID"
          name="gameID"
          rules={[{ required: true, message: "Please input your gameID!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Content>
  );
};

export const Game: React.FC = () => {
  const { gameID } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchGame = async () => {
      const game = await GameClient.GetGame(Number(gameID));
      dispatch(updateGame(game));
    };
    fetchGame();
  }, [gameID]);

  useInterval(
    () => {
      const fetchGame = async () => {
        const game = await GameClient.GetGame(Number(gameID));
        dispatch(updateGame(game));
      };
      fetchGame();
    },
    2000 // 1 sec
  );

  if (!gameID) {
    return <div>Need a game ID</div>;
  }

  return (
    <Content style={{ padding: "10px 10px" }}>
      <Market></Market>
      <div style={{ padding: "10px" }}></div>
      <PlayerArea></PlayerArea>
      <div style={{ padding: "10px" }}></div>
      <PlayerActions></PlayerActions>
    </Content>
  );
};
