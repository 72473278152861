import React from "react";
import { ShoppingCartOutlined, ShoppingTwoTone } from "@ant-design/icons";
import { List, Space, Card, Row } from "antd";
import "./Market.css";
import { GamePhase, CardPile, updateGame } from "../../store/gameSlice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import GameClient from "../../GameClient";

type CardPileProps = {
  cardName: string;
  count: number;
};

const CardPileComponent: React.FC<CardPileProps> = ({ cardName, count }) => {
  const gID = useAppSelector((state) => state.game.id);
  const dispatch = useAppDispatch();

  const buyCard = (gameID?: number) => {
    const apiBuyCard = async () => {
      if (!gameID) {
        return;
      }

      try {
        const gameState = await GameClient.BuyCard(gameID, cardName);
        dispatch(updateGame(gameState));
      } catch (e) {
        alert(e);
      }
    };

    apiBuyCard();
  };

  return (
    <Card
      style={{ width: 170 }}
      cover={<img alt={cardName} src={"/cards/" + cardName + ".jpg"} />}
      hoverable
      onClick={() => buyCard(gID)}
      //actions={[<ShoppingTwoTone key="buy" onClick={() => buyCard(gID)} />]}
      //extra={count}
      bodyStyle={{ padding: "0" }}
      size="small"
    >
      <div className="card-count-market">{count}</div>
    </Card>
  );
};

type MarketRowProps = {
  cardPiles: CardPile[];
};
const MarketRow: React.FC<MarketRowProps> = ({ cardPiles }) => {
  let piles: React.ReactElement[] = [];
  cardPiles.forEach((pile) => {
    piles.push(
      <CardPileComponent
        cardName={pile.name}
        count={pile.count}
      ></CardPileComponent>
    );
  });

  return (
    <Space direction="horizontal" size="small" style={{ display: "flex" }}>
      {piles}
    </Space>
  );
};

const Market: React.FC = () => {
  const game = useAppSelector((state) => state.game);

  const oneSixCards = Array.from(game.market.oneSix);
  const sevenTwelveCards = Array.from(game.market.sevenTwelve);
  const landmarkCards = Array.from(game.market.landmarks);

  return (
    <div className="market">
      <Space direction="horizontal" size="small" style={{}}>
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          <MarketRow cardPiles={oneSixCards}></MarketRow>
          <MarketRow cardPiles={sevenTwelveCards}></MarketRow>
          <MarketRow cardPiles={landmarkCards}></MarketRow>
        </Space>
        <List
          className="action-log"
          size="small"
          bordered
          dataSource={game.actionHistory}
          renderItem={(item) => <div>{item}</div>}
        />
      </Space>
    </div>
  );
};

export default Market;
