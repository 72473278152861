import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export enum GamePhase {
  Initial = "initial",
  InitialBuy = "initial_buy",
  Roll = "roll",
  Buy = "buy",
  Finished = "finished",
  Choose = "choose",
}

export interface Player {
  id: number;
  name: string;
  money: number;
  establishments: CardPile[];
}

export interface Establishment {
  name: string;
}

export interface CardPile {
  name: string;
  count: number;
}

export interface GameState {
  id: number;
  market: {
    oneSix: CardPile[];
    sevenTwelve: CardPile[];
    landmarks: CardPile[];
  };
  players: Player[];
  yourPlayerId?: number;
  currentPlayerIndex: number;
  currentPhase: GamePhase;
  actionHistory: string[];
}

// Define the initial state using that type
const initialState: GameState = {
  id: 0,
  market: {
    oneSix: [],
    sevenTwelve: [],
    landmarks: [],
  },
  players: [],
  currentPlayerIndex: 0,
  currentPhase: GamePhase.Initial,
  actionHistory: [],
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    updateGame: (state, action: PayloadAction<GameState>) => {
      const payload = action.payload;
      state.currentPhase = payload.currentPhase;
      state.players = payload.players;
      state.market = payload.market;
      state.id = payload.id;
      state.currentPlayerIndex = payload.currentPlayerIndex;
      state.actionHistory = payload.actionHistory;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateGame } = gameSlice.actions;

export default gameSlice.reducer;
