import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ChosenCard {
  playerId: number;
  cardName: string;
}

export interface ChosenCardsState {
  cards: ChosenCard[];
}

// Define the initial state using that type
const initialState: ChosenCardsState = {
  cards: [],
};

export const chosenCardsSlice = createSlice({
  name: "chosenCards",
  initialState,
  reducers: {
    addChosenCard: (state, action: PayloadAction<ChosenCard>) => {
      state.cards.push(action.payload);
    },
    removeChosenCard: (state, action: PayloadAction<ChosenCard>) => {
      const removedCard = action.payload;
      state.cards = state.cards.filter((card) => {
        return !(
          removedCard.playerId === card.playerId &&
          removedCard.cardName === card.cardName
        );
      });
    },
    resetChosenCards: (state) => {
      state.cards = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addChosenCard, removeChosenCard, resetChosenCards } =
  chosenCardsSlice.actions;

export default chosenCardsSlice.reducer;
