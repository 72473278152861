import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import NewGame from "./pages/game/NewGame";
import { Game, SelectGame } from "./pages/game/Game";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
//<Route path="/games" element={<StartGame />} />
//<Route path="/game/" element={<Game />} />
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="login" element={<Login />} />
            <Route path="game/" element={<SelectGame />} />
            <Route path="game/:gameID" element={<Game />} />
            <Route path="game/new" element={<NewGame />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
