import {
  RadioChangeEvent,
  Radio,
  Button,
  Space,
  Form,
  Collapse,
  Layout,
} from "antd";
import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { updateGame } from "../../store/gameSlice";
import { useNavigate } from "react-router-dom";
import GameClient from "../../GameClient";

const { Header, Content, Footer } = Layout;

const NewGame: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [businessCenterBehavior, setBusinessCenterBehavior] =
    useState("default");

  const onFinish = async () => {
    const gameState = await GameClient.NewGame(businessCenterBehavior);
    dispatch(updateGame(gameState));
    navigate(`/game/${gameState.id}`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setBusinessCenterBehavior(e.target.value);
  };

  return (
    <Content style={{ padding: "50px 50px" }}>
      <div style={{ textAlign: "center" }}>
        <Space direction="vertical" align="center">
          <Collapse>
            <Collapse.Panel header="Advanced" key="1">
              <b style={{ paddingRight: "50px" }}>Business Center:</b>
              <Radio.Group onChange={onChange} value={businessCenterBehavior}>
                <Space direction="vertical" align="start">
                  <Radio value={"default"}>Default</Radio>
                  <Radio value={"removed"}>
                    Do not include Business Center
                  </Radio>
                  <Radio value={"max_one"}>
                    You can only have one Business Center
                  </Radio>
                  <Radio value={"max_two"}>
                    You can only have two Business Centers
                  </Radio>
                  <Radio value={"equal_trade"}>
                    You can only exchange cards of equal or lesser build cost
                    than your own
                  </Radio>
                </Space>
              </Radio.Group>
            </Collapse.Panel>
          </Collapse>
        </Space>

        <Form
          style={{ padding: "20px" }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              New Game
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
};

export default NewGame;
